.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #937e6b;
}

.ant-picker-ranges .ant-picker-ok .ant-btn-primary {
  background-color: #937e6b;
  border-color: #937e6b;
  color: white;
  width: 52px;
  height: 29px;
  padding-top: 1px;
}

.ant-picker-ranges .ant-picker-ok .ant-btn-sm {
  display: none;
}

.ant-picker-ranges .ant-picker-now-btn {
  color: #495258;
  border: 1px solid #937e6b;
  border-radius: 40px;
  padding: 5px 15px;
  margin-left: 20px;
}

.ant-picker-time-panel-column:not(:first-child) {
  border: none;
}

.ant-picker-panel {
  display: flex;
  flex-direction: column-reverse;
}
.ant-picker-now .ant-picker-now-btn {
  margin-left: 0;
}
